/*
* Global CSS of Help Center
*/
.p-40 {
    padding: 40px;
  }
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  .profile-pg-inner-wrap {
    border: 1px solid #e6e6e6;
  }
  .no-order-btn,
  .no-wishlist {
    /* color: #fff !important; */
    /* background: var(--button-primary) !important; */
    color: var(--color-ButtonTextRegular);
    background-color: var(--color-ButtonBackRegular);
    font-size: 18px !important;
    font-weight: bold !important;
    font-family: "GTWalsheimPro-Medium-" !important;
    line-height: 1.11 !important;
  }
  .no-order-btn:hover,
  .no-wishlist:hover {
    background-color: var(--color-ButtonBackHover);
    color: var(--color-ButtonTextHover);
  }
  
  /*
  * Breadcrumbs CSS
  */
  .yt-mb-80 {
    margin-bottom: 80px;
  }
  .profile-pg-breadcrumbs {
    color: var(--color-dark-grey);
    font-size: 12px;
    line-height: 1.17;
  }
  .profile-pg-home {
    color: var(--color-cyan) !important;
    /* font-weight: 500 !important; */
    font-size: 12px;
    line-height: 1.17;
    /* font-family: "GTWalsheimPro-Medium"; */
  }
  .profile-pg-home:hover {
    color: var(--color-cyan) !important;
    font-weight: 500 !important;
  }
  .profile-pg-current {
    color: black !important;
    font-size: 12px;
    font-weight: bold !important;
    line-height: 1.17;
    /* font-family: "GTWalsheimPro-Medium"; */
  }
  .profile-pg-beckfrom-ct-page {
    margin-top: 50px;
    color: var(--color-black);
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
  }
  .profile-pg-mb-10 {
    margin-bottom: 10px;
  }
  .profile-pg-mb-30 {
    margin-bottom: 30px;
  }
  .profile-pg-mt-30 {
    margin-bottom: 30px;
  }
  .profile-pg-mb-50 {
    margin-bottom: 50px;
  }
  .radius-10 {
    border-radius: 10px;
  }
  .list-style-none {
    list-style: none;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li:first-child {
    padding-top: 0;
  }
  .profile-pg-address-list {
    border: 1px solid #e6e6e6;
    padding: 40px;
    position: relative;
  }
  /*
  * Left Col CSS
  */
  .profile-pg-inner-contnet .profile-pg-tabs-name li {
    padding: 12.5px 0;
    border-bottom: 2px solid #cae2fe;
    font-size: 18px;
    line-height: 1.11;
    color: var(--color-black);
    cursor: pointer;
    position: relative;
    font-weight: 500;
  }
  .profile-pg-inner-contnet .pg-profile-box li:first-child.active::after {
    opacity: 0;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
    content: "";
    height: 75%;
    width: 3px;
    background: var(--color-primary);
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li:last-child {
    border: none;
    padding-bottom: 0;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li.active {
    font-weight: 500;
    color: var(--color-primary);
  }
  
  /*
  * Right Col CSS
  */
  .order-data-scroll {
    max-height: 145vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .order-data-scroll::-webkit-scrollbar {
    width: 4px;
  }
  .order-data-scroll::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    width: 2px;
  }
  .order-data-scroll::-webkit-scrollbar-thumb {
    background: #888;
  }
  .order-data-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .loadMoreBtn {
    text-align: center !important;
    margin-top: 20px;
    margin: 20px auto;
    width: 52px;
  }
  
  .loadMoreBtn .btn-outline-secondary:hover {
    color: #fff;
    background-color: #3e454f;
    border: 1px solid #3e454f;
  }
  .profile-pg-tab-main-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  .profile-pg-tab-sub-title {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-black);
    line-height: 1.15;
  }
  .ht-tab-description {
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.18px;
    color: var(--color-dark-grey);
  }
  
  /*
  */
  .img-upload svg {
    font-size: 34px;
    color: var(--color-black);
  }
  .img-upload {
    width: 109px;
    height: 109px;
    background-color: #f6f8fa;
    border-radius: 6px;
  }
  .profile-name {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  .profile-email {
    font-size: 18px;
    line-height: 1.11;
    color: var(--color-dark-grey);
  }
  .profile-item-wrap {
    position: relative;
  }
  .profile-notiy {
    position: absolute;
    background-color: var(--color-primary);
    font-size: 13px;
    line-height: 1.15;
    color: var(--color-white);
    padding: 5px;
    border-radius: 50px;
    top: -12px;
    left: 30px;
    width: 20px;
    text-align: center;
    height: 23px;
  }
  .notifications-notiy {
    position: absolute;
    background-color: var(--color-primary);
    font-size: 13px;
    line-height: 1.5;
    color: var(--color-white);
    padding: 5px 0px;
    border-radius: 50px;
    top: -8px;
    left: 36px;
    width: 30px;
    text-align: center;
    height: 30px;
  }
  .profile-pg-title {
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 1.13 !important;
    color: var(--color-black) !important;
  }
  .profile-data-tag {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-dark-grey);
  }
  .profile-user-name {
    font-size: 18px;
    line-height: 1.11;
    color: var(--color-black);
    border-bottom: 2px solid #f6f8fa;
  }
  .profile-edit-btn {
    border-radius: 6px;
    border-color: var(--color-ButtonBackRegular);
    background-color: var(--color-ButtonBackRegular);
    color: var(--color-ButtonTextRegular);
    width: 100%;
    max-width: 180px;
    font-size: 18px !important;
    line-height: 1.11 !important;
    font-weight: bold !important;
    /* font-family: "GTWalsheimPro-Medium-" !important; */
  }
  
  .profile-edit-btn:hover {
    background-color: var(--color-ButtonBackHover);
    color: var(--color-ButtonTextHover);
  }
  
  .profile-edit-pass {
    /* color: var(--color-primary); */
    color: var(--color-ButtonTextRegular);
  }
  
  .profile-edit-pass,
  .profile-edit-btn {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.11;
    padding: 15px 15px;
  }
  
  .yt-cm-lt-col {
    padding-right: 7px;
    /* max-width: 480px; */
  }
  .yt-cm-rt-col {
    padding-left: 7px;
  }
  @media (min-width: 768px) {
    .yt-profile-mb-ttl {
      display: none;
    }
  }
  @media (min-width: 1280.88px) {
    .yt-cm-lt-col {
      padding-right: 10px;
    }
    .yt-cm-rt-col {
      padding-left: 10px;
    }
  }
  @media (min-width: 1900px) {
    .yt-cm-lt-col {
      padding-right: 20px;
    }
    .yt-cm-rt-col {
      padding-left: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1900px) {
    .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
      right: -30px !important;
    }
    .pg-profile-box .img-upload {
      width: 80px;
      height: 80px;
    }
    .yt-cm-lt-col {
      padding-right: 15px;
    }
    .yt-cm-rt-col {
      padding-left: 15px;
    }
    .yt-cm-ptc-row.yt-btm-inf.row {
      margin-top: 30px;
    }
    .yt-cm-rt-col .profile-pg-inner-wrap {
      padding: 40px 30px 35px !important;
    }
    .profile-name {
      font-size: 21px;
      line-height: 1.14;
    }
    .profile-email {
      line-height: 1.13;
      font-size: 16px;
    }
    .profile-pg-title {
      font-size: 26px;
      line-height: 1.12;
    }
    .profile-pg-inner-wrap.profile-inner-tab-wrap {
      padding: 20px;
    }
    .profile-pg-inner-wrap.profile-inner-tab-content {
      padding: 30px;
    }
    .profile-item-icn {
      max-width: 35px;
    }
    .profile-notiy {
      font-size: 12px;
      line-height: 1.17;
      padding: 3px 0px;
      border-radius: 50px;
      width: 20px;
      text-align: center;
      left: 20px;
    }
    .profile-pg-inner-contnet .profile-pg-tabs-name li {
      font-weight: normal;
    }
    .profile-edit-pass,
    .profile-edit-btn {
      font-size: 16px;
      line-height: 1.13;
    }
    .profile-pg-breadcrumbs {
      margin-bottom: 30px;
    }
    .profile-pg-address-list {
      margin-bottom: 20px !important;
      padding: 30px;
    }
  }
  /*
  * Screen for under 1280 Resolution 
  */
  @media (max-width: 1279.92px) {
    .profile-item-wrap .profile-item-name {
      font-size: 14px;
      line-height: 1.14;
    }
    .profile-item-wrap .profile-item-icn {
      max-width: 46px;
      margin-right: 17px !important;
    }
    .profile-pg-inner-wrap,
    .tab-content .profile-pg-inner-wrap {
      padding: 15px !important;
    }
    .profile-inner-tab-content {
      padding: 18px 15px 15px !important;
    }
    .profile-notiy {
      font-size: 12px;
      line-height: 1.17;
    }
    .yt-cm-lt-col {
      max-width: 250px;
      /* box-sizing: content-box; */
    }
    .img-upload {
      width: 43px;
      height: 43px;
    }
    .yt-profile-img-nm-wrap .img-upload {
      width: 90px;
      height: 90px;
    }
    .yt-profile-img-nm-wrap {
      margin-bottom: 20px !important;
    }
    .img-upload svg {
      font-size: 16px;
    }
    .profile-data-wrap {
      max-width: 330px;
    }
    .profile-data-tag {
      font-size: 12px;
      line-height: 1.17;
    }
  
    .profile-edit-btn {
      padding-top: 14px;
      padding-bottom: 14px;
      background-color: var(--button-primary);
      color: var(--button-text-primary);
      border-color: var(--button-primary);
    }
    .profile-edit-pass,
    .profile-edit-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
    }
    .profile-name {
      font-size: 20px;
      line-height: 1.15;
    }
    .profile-email {
      font-size: 12px;
      line-height: 1.17;
      max-width: 75%;
      overflow-x: auto;
    }
    .user-profileinfo {
      margin-left: 12px !important;
    }
    .profile-pg-inner-wrap.profile-inner-tab-wrap {
      margin-bottom: 10px;
    }
    .profile-pg-title {
      font-size: 24px;
      line-height: 1.17;
      margin-bottom: 14px !important;
    }
    .yt-cm-ptc-col {
      /* -ms-flex: 0 0 100%; */
      flex: 0 0 100%;
      max-width: 100%;
    }
    .profile-pg-breadcrumbs {
      margin-bottom: 58px;
    }
    .yt-profile-img-nm-wrap .img-upload svg {
      font-size: 34px;
    }
    .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
      right: -15px;
    }
    .profile-pg-address-list {
      padding: 20px;
      margin-bottom: 10px;
    }
  }

  .filterpage1 .yt-cm-row {
    display: flex;
    gap: 16px;
  }

  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767px) {
    .profile-pg-title {
      margin-bottom: 10px !important;
    }
    .user-review {
      width: 100%;
      margin-bottom: 30px;
    }
    .img-upload svg {
      font-size: 34px;
    }
    .profile-pg-title {
      font-size: 18px;
      line-height: 1.11;
      /* padding-left: 8px; */
    }
    .profile-pg-breadcrumbs {
      margin-bottom: 10px;
      margin-top: 10px !important;
    }
    .profile-pg-inner-wrap,
    .tab-content .profile-pg-inner-wrap {
      padding: 20px !important;
    }
  
    .yt-profile-mb-ttl.profile-pg-title {
      display: block;
    }
    .yt-cm-row {
      display: block;
    }
    .yt-cm-row .yt-cm-rt-col,
    .yt-cm-row .yt-cm-lt-col {
      width: 100%;
      max-width: 100%;
    }
    .yt-cm-row.row .yt-cm-rt-col,
    .yt-cm-row.row .yt-cm-lt-col {
      padding-right: 6px;
      padding-left: 6px;
    }
  
    .yt-pagination {
      width: 100% !important;
    }

    .profile-edit-pass {
      padding: 0;
      margin-right: auto !important;
    }
    .profile-edit-btn {
      padding-top: 14px;
      padding-bottom: 14px;
      background-color: var(--button-primary);
      color: var(--button-text-primary);
      border-color: var(--button-primary);
    }
    .profile-edit-pass,
    .profile-edit-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
    }
    .profile-user-name {
      font-size: 18px !important;
      line-height: 1.11;
      margin-bottom: 24px;
      font-family: "GTWalsheimPro-Regular-" !important;
      color: var(--color-black) !important;
    }
    .profile-data-tag {
      font-size: 13px;
      line-height: 1.15;
    }
    .yt-profile-img-nm-wrap {
      margin-bottom: 24px !important;
    }
    .yt-cm-row .bb-cm-lt-col {
      display: none;
    }
  }
  
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.98px) {
    .profile-pg-address-list {
      padding: 20px;
      margin-bottom: 14px;
    }
  }
  