.read-delete-buttons {
  float: right;
  margin-top: -5%;
  margin-right: 1%;
}
.read-delete-btn {
  background: none !important;
  /* border: none; */
  background-color: transparent !important;
  border: none !important;
  color: var(--color-black) !important;
}
.notification-title {
  font-weight: 500;
  color: #3e454f;
}
.notification-sub-title {
  color: #a3a3a3;
}
.pp-sa-list-notify {
  list-style: none;
  max-height: 140vh;
  overflow: auto;
  width: 100%;
}
.pp-sa-list-notify::-webkit-scrollbar {
  width: 4px;
}
.pp-sa-list-notify::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 2px;
}
.pp-sa-list-notify::-webkit-scrollbar-thumb {
  background: #888;
}
.pp-sa-list-notify::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.delete-btn {
  width: 17.8px;
  height: 22px;
  /* margin: 0.2px 0 0 566.2px; */
  object-fit: contain;
  margin-top: 8%;
  cursor: pointer !important;
}
.delete-btn:hover {
  cursor: pointer !important;
}

.pp-na-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-na-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
  width: 100%;
  max-width: 200px;
}
.pp-no-addr-btn:hover {
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.pp-sa-list-none {
  list-style: none;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  padding-left: 32px;
}

.profile-pg-inner-no-add img {
  max-height: 217px;
}
.profile-pg-address-list {
  border: 1px solid #e6e6e6;
}
.profile-pg-inner-wrap {
  border: 1px solid #e6e6e6;
}
.pp-sa-action-wrap {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.profile-pg-address-list {
  padding: 40px;
  position: relative;
}
.profile-pg-sa-no-address-main-wrap {
  padding: 165px 0;
}
.pp-sa-type {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.pp-sa-address {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.pp-sa-action-wrap .btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: darkgray;
}
.pp-sa-action-wrap .pp-add-delete-btn {
  color: var(--color-orange);
}
.radius-10 {
  border-radius: 10px;
}
.profile-pg-mb-30 {
  margin-bottom: 30px;
}
.pp-sa-all-addres-list li {
  cursor: pointer;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  max-width: 100%;
}

.loadMoreBtn {
  text-align: center !important;
  margin-top: 20px;
  margin: 20px auto;
  width: 52px;
}

.loadMoreBtn .btn-outline-secondary:hover {
  color: #fff;
  background-color: #3e454f;
  border: 1px solid #3e454f;
}

.yt-load-more {
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-black);
}
.pp-sa-list-notify li:hover {
  box-shadow: 0 10px 50px -12px hsl(0deg 0% 67% / 90%);
  transform: translateY(-6px);
  transition: all 0.55s ease;
  /* transform: translateY(-3px);
    transition: all 0.55s ease; */
}
.pp-sa-address,
.pp-no-addr-btn {
  font-weight: bold;
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .pp-sa-address,
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 45px 0;
  }
  .profile-pg-inner-no-add img {
    max-height: 185px;
    margin-bottom: 30px !important;
  }
  .pp-sa-img-wrap img {
    max-width: 50px;
    height: 50px;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .profile-pg-address-list {
    margin-bottom: 20px !important;
    padding: 30px;
  }
}
/*
    * Screen for under 1280 Resolution 
    */
@media (max-width: 1279.92px) {
  .profile-pg-sa-no-address-main-wrap {
    padding: 39px 0;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 39px !important;
  }
  .pp-na-ttl {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 40px !important;
  }
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 10px;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    padding-left: 14px;
    margin-top: 7px;
    padding-right: 125px;
  }
  .pp-sa-type {
    font-size: 16px;
    line-height: 1.19;
  }
  .pp-sa-address {
    line-height: 1.36;
    font-size: 14px;
  }
  .pp-sa-img-wrap {
    max-width: 80%;
  }
  .pp-sa-action-wrap .btn {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 0;
    padding-right: 0;
  }
  .read-delete-buttons {
    float: none;
  }
}

/*
    * Screen for under 768 Resolution 
    */
@media (max-width: 767.98px) {
  .pp-sa-info-wrap {
    margin: 20px 0;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 18px 0 5px;
  }
  .pp-na-ttl {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 34px !important;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .pp-no-addr-btn {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 14px;
  }
  .pp-sa-action-wrap {
    position: initial !important;
    transform: none !important;
    width: 100%;
  }
  .pp-sa-action-wrap .pp-sa-delet img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .pp-sa-action-wrap .pp-sa-edit,
  .pp-sa-action-wrap .pp-sa-delet {
    width: 50%;
    text-align: center !important;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    margin: 0 0 16px !important;
    padding: 0 20px 0 15px;
  }
  .pp-sa-action-wrap {
    padding-top: 10px;
    border-top: solid 1px #f2f6f8;
    margin-top: 16px;
  }
  .pp-sa-type {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.2;
  }
  .pp-sa-address {
    font-size: 15px;
    line-height: 1.2;
  }
  .pp-sa-action-wrap .btn {
    font-size: 15px;
    line-height: 1.2;
  }
  .read-delete-buttons {
    float: none;
  }
}
